<template>
  <section id="intro" class="section_container pt-5">
    <video-background
      :src="require('@/assets/video/video-isun-intro.mp4')"
      class="video-background"
      overlay="linear-gradient(45deg,#3e3c3c10,#12121451)"
    >
      <!-- style="position: absolute; left: 0; right: 0; margin: auto; top: 25%" -->
      <v-container class="video-overlay">
        <v-row align="center">
          <v-col cols="12">
            <default-home-banner />
          </v-col>
          <v-col cols="12" style="text-align: center; position:sticky;">
            <home-personal-cabinet-button />
          </v-col>
        </v-row>
      </v-container>
    </video-background>
  </section>
</template>
<script>
export default {
  name: "HomeSectionIntro",
  components: {
    DefaultHomeBanner: () => import("@/views/Home/HomeBanner.vue"),
    HomePersonalCabinetButton: () =>
      import("@/views/Home/HomePersonalCabinetButton.vue"),
  },
};
</script>
<style lang="scss" scoped>
.video-background {
  max-height: 400px;
  height: 100vh;
}
.video-overlay {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 25%;
}
.section_container {
  background-color: #ffffff;
  width: 100%;
  margin: 0 auto;
}
.section_link {
  color: white;
  text-decoration: none;
}
.section_link:hover {
  color: black;
}
</style>